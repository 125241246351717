.wrapper {
  --search-height: 35px;
  --search-top-spacer: 5px;
  --search-border-radius: 92px;
  --search-button-width: 52px;

  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // height: var(--default-layout-header-height);
  box-shadow: 0px 1px 1px RGB(0 0 0 / 12%);
  background: linear-gradient(271deg, #1b477b, #ff0011);
  display: flex;
  justify-content: center;
}

.inner {
  height: 100%;
  width: var(--default-layout-width);
  // display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--default-layout-horizontal-spacer);
}

.logo-center {
  display: flex;
  align-items: center;
}

.logo-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logo-link {
  display: flex;
}

.more-btn {
  font-size: 2rem;
  margin-left: 28px;
  padding: 4px 8px;
  background-color: transparent;
  cursor: pointer;
}

//Menu
.menu-items {
  width: 224px;
}
.logo {
  // width: 100%;
  height: 38px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 5px;
  padding: 3px;
}
.title-note-sim {
  color: aliceblue;
  padding: 5px;
}
.color-trang {
  color: #fff;
  font-size: 16px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  // padding-top: 10px;
  margin-top: 5px;
}
.color-hotline {
  color: #fff;
  align-items: center;
  font-size: 10px;
}
.color-gold {
  color: gold;
}
