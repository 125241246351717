.header-title-df {
  font-size: 15px;
  border: 1px solid var(--blue-mb);
  min-height: 65px;
  border-radius: 4px;
  margin: 5px;
  span b {
    color: var(--blue-mb);
  }
}
.header-title-item-df {
  background-color: var(--blue-mb);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.detail-item-sim {
  padding: 5px;
}
.font-size-small {
  font-size: 12px;
}
.font-size-medium {
  font-size: 16px;
}
.title-item {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
}
.svg_icons_ticker_df {
  transform: scale(0.7);
  color: var(--blue-mb);
}
// brg blue
.header-title-blue {
  font-size: 15px;
  border: 1px solid var(--blue);
  min-height: 65px;
  border-radius: 4px;
  margin: 5px;
  span b {
    color: var(--blue);
  }
}
.header-title-item-blue {
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.svg_icons_ticker_blue {
  transform: scale(0.7);
  color: var(--blue);
}

// brg green
.header-title-green {
  font-size: 15px;
  border: 1px solid var(--green);
  min-height: 65px;
  border-radius: 4px;
  margin: 5px;
  span b {
    color: var(--green);
  }
}
.header-title-item-green {
  background-color: var(--green);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.svg_icons_ticker_green {
  transform: scale(0.7);
  color: var(--green);
}

// brg green
.header-title-red {
  font-size: 15px;
  border: 1px solid var(--primary);
  min-height: 65px;
  border-radius: 4px;
  margin: 5px;
  span b {
    color: var(--primary);
  }
}
.header-title-item-red {
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.svg_icons_ticker_red {
  transform: scale(0.7);
  color: var(--primary);
}
.color-red {
  color: #d5001e;
}
.cut-str {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
