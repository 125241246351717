// @import 'normalize.css';
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: 'Messi';
  src: local('Messi'), url('/assets/fonts/ImprintMTShadow.ttf');
  // font-weight: bold;
}
@font-face {
  font-family: 'Arendon';
  src: local('Arendon'), url('/assets/fonts/VnClarendon.ttf');
  // font-weight: bold;
}
@font-face {
  font-family: 'sofiapro-semibold';
  src: local('sofiapro-semibold'), url('/assets/fonts/sofiapro-semibold.otf');
  // font-weight: bold;
}
@font-face {
  font-family: 'UTM-Avo';
  src: local('UTM-Avo'), url('/assets/fonts/UTM-Avo.ttf');
  // font-weight: bold;
}
@font-face {
  font-family: 'LeagueVaria';
  src: local('LeagueVaria'), url('/assets/fonts/LeagueSpartan-VariableFont_wght.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'Abril';
  src: local('Abril'), url('/assets/fonts/SVN-AbrilFatface.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'VCENTB';
  src: local('VCENTB'), url('/assets/fonts/VCENTB.TTF');
  font-weight: bold;
}
@font-face {
  font-family: 'fantasys';
  src: local('fantasys'), url('/assets/fonts/UTM-Impact.ttf');
  // font-weight: bold;
}

:root {
  --primary: #d70018;
  --black: #000;
  --text-color: #333;
  --white: white;
  --blue: #229fd9;
  --green: #00918c;
  --blue-mb: #0967c5;

  //Default Layout
  --default-layout-header-height: 60px;
  --default-layout-width: 1150px;
  --default-layout-horizontal-spacer: 10px;

  --bgr-dark: #828282;
  --bgr-dark-frame: #000;
  --bgr-dark-frame-color: white;
  --bgr-light: white;
}

* {
  margin: 0;
  padding: 0;
  // box-sizing: border-box;
}

html {
  overflow: hidden;
}

body {
  font-family: sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
  color: var(--text-color);
  overflow-y: overlay;
  background-color: #f9f9f9;
  overflow-x: hidden;
}
body {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}
body::after {
  content: '';
  background-image: url('/assets/images/brgbook.jpg');
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
  // border-radius: 0;
  // width: 5px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
}
// Fonts

@font-face {
  font-family: ProximaNova;
  src: url('/assets/fonts/Proxima-Nova-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: ProximaNova;
  src: url('/assets/fonts/Proxima-Nova-Semibold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: ProximaNova;
  src: url('/assets/fonts/ProximaNova-Semibold.woff2');
  font-weight: 700;
}

@font-face {
  font-family: SofiaPro;
  src: url('/assets/fonts/sofiapro-semibold.otf');
  font-weight: 700;
}

.dark {
  background-color: var(--bgr-dark-frame) !important;
  color: var(--bgr-dark-frame-color) !important;
}
canvas {
  width: 100%;
}
// * {
//   pointer-events: none;
// }
