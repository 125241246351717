.toolbar-editor {
  background: #000000d9;
  // background: linear-gradient(271deg, #1b477b, #ff0011);
  border-radius: 10px;
  position: fixed;
  z-index: 10;
  bottom: 20px;
  left: 20px;
  width: 90%;
}
.btn-add-text {
  background: #000000b0;
  border-radius: 10px;
  position: fixed;
  z-index: 10;
}
.color-icon-toolbar {
  color: aliceblue;
}
.toolbar-input {
  input {
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    // font-weight: 600;
  }
  textarea {
    // border: 1px solid #fff;
    // border-radius: 5px;
    color: #fff;
    // font-weight: 600;
    padding: 5px;
  }
}
// css phím lên xuống
.rotateMode {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background-color: black;
  color: white;
  position: relative;
  border: 1px dashed #fff;
  .left {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    border-radius: 5px;
    margin-left: 5px;
    background-color: white !important;
  }
  .right {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    border-radius: 5px;
    margin-right: 5px;
    background-color: white !important;
  }

  .up {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    margin-top: 5px;
    background-color: white !important;
  }

  .down {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: white !important;
  }
}

.title-sort-sim {
  background-color: #ef0000;
  color: #fff;
}

.touchactionNone {
  touch-action: none;
  overflow: hidden;
}
.touchactionAuto {
  touch-action: auto;
  height: 90vh;
}
.color-red {
  color: #ef0000;
}
.color-white {
  color: #fff !important;
}
.color-blue {
  color: blue !important;
}
.domEl {
  // position: relative;
  // height: 230px;
  // width: 500px;
  // padding: 10px;
  // float: left;
  display: flex;
  align-items: center;
  // background: #fffcae;
  border: 1px solid #181818;
}
.dis-none {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.bgr-red-color-gold {
  background: #ef0000;
  color: gold;
}
.box-list-subcate {
  overflow-x: scroll;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #ffe0e0;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #ff6e7f;
  border-radius: 10px;
}
.list-subcate {
  width: max-content;
}
// .box-list-subcate::-webkit-scrollbar {
//   display: none;
// }

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

img {
  animation: fadeIn 1s ease-in-out;
}
img {
  object-fit: cover;
}
.css-image-boder {
  background: #ff000026;
  padding: 5px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
