.search,
.search-result {
  width: 361px;
}

.search {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  padding-left: 16px;
  // margin-top: 10px;
  background-color: #ffdfe3;
  border-radius: 10px;
  border: 1.5px solid transparent;

  input {
    flex: 1;
    height: 100%;
    width: inherit;
    color: #000;
    padding-right: 30px;
    font-size: 16px;
    background-color: #ffdfe3;
    caret-color: #e10000;

    &::placeholder {
      color: #1f1f1f54;
    }
  }

  input:not(:placeholder-shown) ~ .search-btn {
    color: RGB(210 0 0);
  }

  &::after {
    content: '';
    position: absolute;
    top: var(--search-top-spacer);
    right: var(--search-button-width);
    width: 1px;
    height: calc(var(--search-height) - var(--search-top-spacer) * 2);
    background-color: rgba(22, 24, 35, 0.12);
  }

  &:focus-within {
    border-color: rgba(22, 24, 35, 0.2);
  }
}

.title-guide {
  border: 1px solid red;
  padding: 5px 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 15px;
}

.icon-guide {
  position: fixed;
  bottom: 45px;
  right: 10px;
  z-index: 10;
  color: #575757;
  cursor: pointer;
}

.search-title {
  padding: 5px 12px;
  font-size: 1.3rem;
  font-weight: 600;
  color: rgba(22, 24, 35, 0.5);
}

.clear,
.loading {
  position: absolute;
  right: calc(var(--search-button-width) + 16px);
  top: 50%;
  transform: translateY(-50%);
  color: rgba(22, 24, 35, 0.34);
  background-color: transparent;
  margin-top: 2px;
  // padding-top: 5px;
}

.loading {
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  from {
    transform: translateY(-50%) rotate(0);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.search-btn {
  width: var(--search-button-width);
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  // padding-top: 2px;
  color: #e1000063;
  background-color: #e100000a;

  &:hover {
    cursor: pointer;
    background-color: #e100001c;
  }
  &:active {
    background-color: #e1000021;
  }
}
